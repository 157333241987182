import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './home.scss'; // Assuming you have a CSS file for styles

const EmployeeHome = () => {
  const navigate = useNavigate();

  // State management
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [showAllAnnouncements, setShowAllAnnouncements] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAllSchedules, setShowAllSchedules] = useState(false); // New state for uploaded schedules toggle
  const [showAllFiles, setShowAllFiles] = useState(false);

  // Function to toggle Uploaded Schedules
  const toggleSchedules = () => {
    setShowAllSchedules((prev) => !prev);
  };

  // Retrieve user ID and role from local storage (assuming set during login)
  const userId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('role'); // Retrieve the role

  // Fetch user profile on component mount
  useEffect(() => {
    const fetchUserName = async () => {
      try {
        if (!userId) {
          navigate('/login');
          return;
        }

        const response = await axios.get(`https://employeeapi.jetsonweb.com/api/employees/profile`, {
          params: { userId },
          withCredentials: true
        });

        setUserName(response.data.full_name);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user name:', error);
        setError('Failed to load user profile');
        setLoading(false);

        navigate('/login');
      }
    };

    fetchUserName();
  }, [userId, navigate]);

  // Fetch announcements based on the user role
  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        const userRole = localStorage.getItem('role'); // Get role from localStorage
        if (!userRole) return;
  
        const response = await axios.get(`https://employeeapi.jetsonweb.com/api/announcements/${userRole}`);
        setAnnouncements(response.data);
      } catch (error) {
        console.error('Error fetching announcements:', error);
        setError('Failed to load announcements');
      }
    };
  
    fetchAnnouncements();
  }, []);

  // Fetch uploaded files based on the user role
  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const userRole = localStorage.getItem('role'); // Get role from localStorage
        if (!userRole) return;
  
        const response = await axios.get(`https://employeeapi.jetsonweb.com/api/uploadfile/${userRole}`);
        setUploadedFiles(response.data);
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
        setError('Failed to load uploaded files');
      }
    };
  
    fetchUploadedFiles();
  }, []);
  // Handle "See More Announcements"
  const toggleAnnouncements = () => {
    setShowAllAnnouncements((prev) => !prev);
  };

  // Handle Logout
  const handleSignout = (event) => {
    event.preventDefault();

    // Clear user data from local storage
    localStorage.removeItem('userId');
    localStorage.removeItem('role');  // Clear role from storage
    localStorage.removeItem('token');

    // Redirect to login page
    navigate("/login");
  };

  // Handle announcement click
  const handleAnnouncementClick = (announcement) => {
    setSelectedAnnouncement(announcement);
  };

  const handleFileClick = (file) => {
    setSelectedFile(file);
  };

  // Close popup
  const closePopup = () => {
    setSelectedAnnouncement(null);
  };

  const closePopup1 = () => {
    setSelectedFile(null);
  };

  // Handle file click to open the file or show popup
  const handleFileDownload = (fileUrl) => {
    if (fileUrl) {
      window.open(fileUrl, '_blank'); // Open the file in a new tab
    } else {
      alert('File not available');
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading your dashboard...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
        <button onClick={() => navigate('/login')}>Return to Login</button>
      </div>
    );
  }

  // Helper function to check if a post is new (within the last 24 hours)
const isPostNew = (postDate) => {
  const now = new Date();
  const postCreationTime = new Date(postDate);
  const timeDifference = now - postCreationTime; // Time difference in milliseconds
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  return timeDifference < oneDayInMilliseconds;
};

  return (
    <div className="home-container">
      {/* Header Section */}
      <header className="home-header">
        <div className="header-content">
          <h1>Welcome, {userName || 'Employee'}</h1>
          <button onClick={handleSignout} className="logout-button">
            Logout
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="main-content">
        {/* Announcements Section */}
        <section className="content-section">
  <h2>Announcements</h2>
  <div className="content-grid">
    {announcements
      .slice(0, showAllAnnouncements ? announcements.length : 3)
      .map((item) => (
        <div
          key={item.id}
          className="content-card"
          onClick={() => handleAnnouncementClick(item)}
        >
          <h3>{item.title}</h3>
          <p>{item.description}</p>
          <p className="announcement-date">{new Date(item.date).toLocaleString()}</p>

          {/* Add "New" tag if the post is recent */}
          {isPostNew(item.date) && (
            <span className="new-tag">New</span>
          )}
        </div>
      ))}
  </div>
  <div className="see-more">
    <a href="#" onClick={toggleAnnouncements}>
      {showAllAnnouncements ? "See Less Announcements" : "See More Announcements"}
    </a>
  </div>
</section>

        {/* Uploaded Files Section */}
        <section className="files-section">
  <h2>Uploaded Schedules</h2>
  <div className="files-grid">
    <div className="file-container">
      {uploadedFiles
        .slice(0, showAllFiles ? uploadedFiles.length : 3)
        .map((file, index) => (
          <div
            key={index}
            className="file-card"
            onClick={() => handleFileClick(file)}
          >
            <h3>{file.title}</h3>
            <p className="file-date">Date: {new Date(file.announcement_date).toLocaleString()}</p>

            {/* Add "New" tag if the file is recent */}
            {isPostNew(file.announcement_date) && (
              <span className="new-tag">New</span>
            )}
          </div>
        ))}
    </div>
  </div>
  <div className="see-more">
    <a href="#" onClick={() => setShowAllFiles(!showAllFiles)}>
      {showAllFiles ? "See Less" : "See More "}
    </a>
  </div>
</section>

        {/* Popup for Announcement Details */}
        {selectedAnnouncement && (
          <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <h3>{selectedAnnouncement.title}</h3>
              <p>{selectedAnnouncement.description}</p>
              <p>Date: {selectedAnnouncement.date}</p>
              {selectedAnnouncement.file_paths && (
                <button
                  onClick={() => handleFileDownload(selectedAnnouncement.file_paths)}
                  className="download-button"
                >
                  Download File
                </button>
              )}
              <button onClick={closePopup} className="close-popup">Close</button>
            </div>
          </div>
        )}

        {/* Popup for Uploaded Schedules */}
        {selectedFile && (
          <div className="popup-overlay" onClick={closePopup1}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
              <h3>{selectedFile.title}</h3>
              <p>{selectedFile.description || 'No description available'}</p>
              <p>Date: {selectedFile.announcement_date}</p>
              {selectedFile.file_path && (
                <button
                  onClick={() => handleFileDownload(selectedFile.file_path)}
                  className="download-button"
                >
                  Download File
                </button>
              )}
              <button onClick={closePopup1} className="close-popup">Close</button>
            </div>
          </div>
        )}
      </main>

      {/* Footer */}
      <footer id="contact" className="footer">
        <p>
          For inquiries, contact us at{' '}
          <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a>
        </p>
      </footer>
    </div>
  );
};

export default EmployeeHome;
